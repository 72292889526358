import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6cc134d0 = () => interopDefault(import('../../pages/billings/index.vue' /* webpackChunkName: "pages/billings/index" */))
const _d72732aa = () => interopDefault(import('../../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _550e05de = () => interopDefault(import('../../pages/forget-password/index.vue' /* webpackChunkName: "pages/forget-password/index" */))
const _6a60223f = () => interopDefault(import('../../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _a1c67da6 = () => interopDefault(import('../../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _380e4a77 = () => interopDefault(import('../../pages/usage/index.vue' /* webpackChunkName: "pages/usage/index" */))
const _50c4217e = () => interopDefault(import('../../pages/payments/checkout/index.vue' /* webpackChunkName: "pages/payments/checkout/index" */))
const _6d0e1dda = () => interopDefault(import('../../pages/payments/thank-you/index.vue' /* webpackChunkName: "pages/payments/thank-you/index" */))
const _5b9b737a = () => interopDefault(import('../../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _1236b096 = () => interopDefault(import('../../pages/set-password/_token.vue' /* webpackChunkName: "pages/set-password/_token" */))
const _7bca75a5 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/billings",
    component: _6cc134d0,
    name: "billings"
  }, {
    path: "/contact-us",
    component: _d72732aa,
    name: "contact-us"
  }, {
    path: "/forget-password",
    component: _550e05de,
    name: "forget-password"
  }, {
    path: "/login",
    component: _6a60223f,
    name: "login"
  }, {
    path: "/payments",
    component: _a1c67da6,
    name: "payments"
  }, {
    path: "/usage",
    component: _380e4a77,
    name: "usage"
  }, {
    path: "/payments/checkout",
    component: _50c4217e,
    name: "payments-checkout"
  }, {
    path: "/payments/thank-you",
    component: _6d0e1dda,
    name: "payments-thank-you"
  }, {
    path: "/reset-password/:token?",
    component: _5b9b737a,
    name: "reset-password-token"
  }, {
    path: "/set-password/:token?",
    component: _1236b096,
    name: "set-password-token"
  }, {
    path: "/",
    component: _7bca75a5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
